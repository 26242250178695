if (bowser.msie) {
  $('.browser-detection-wrapper').show();
} else if (bowser.chrome) {
} else if (bowser.firefox) {
} else if (bowser.msedge) {
  $('.browser-detection-wrapper').show();
} else if (bowser.opera) {} else {}
// if (bowser.windows) {
// 	update2("Windows");
// } else if (bowser.windowsphone) {
// 	update2("Windows Phone");
// } else if (bowser.mac) {
// 	update2("OS X");
// } else if (bowser.ios) {
// 	update2("IOS");
// } else if (bowser.android) {
// 	update2("Android");
// } else {
// 	$("a").text("not detected or not added to this demo");
// }

$('.ham').on('click', function (f) {
  $('body').toggleClass('menu-active no-scroll');
  $(".menu_item").removeClass('inactive active');
  $('.menu_item ul').slideUp();
});

$('.menu_item').on('click', function (f) {
  $(this).children('ul').slideToggle();
  $(this).children().children('ul').slideToggle(500);
  $(this).siblings().toggleClass('inactive');
});

$(document).keyup(function(e){

  if(e.keyCode === 27)
      $("body").removeClass('menu-active no-scroll');
      $(".menu_item").removeClass('inactive active');
      $('.menu_item ul').slideUp();
});
